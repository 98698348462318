import { groq } from '#imports';

export function getQuery() {
  return groq`*[_type == "section" && !(_id in path('drafts.**'))] | order(position) {
      ...,
      linkedPosts[]->{
        _type,
        slug,
        image,
        publishedAt,
        isChronicle,
        size,
        title,
        subtitle,
        titleColor,
        titleColorFromColorPicker,
        subtitleColorFromColorPicker,
        shadowColorFromColorPicker,
        subtitleColor,
        shadowColor,
        isFullImage,
        backgroundColor,
        isArticleExternalSourceType,
        externalSource,
        crew[]{staff->, speciality->}
      },
      quoteBlock{..., author->},
    }`;
}
