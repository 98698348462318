import { groq } from '#imports';

export function getQuery({ feedName } = {}) {
  return groq`*[_type == "newsFeed" && !(_id in path('drafts.**')) && name == '${feedName}'][0] {
    name,
    "relatedNews": *[_type== "news" && references(^._id)] {
      slug,
      title,
      publishedAt
    }
  }`;
}
